<template>
  <!-- 能耗报表S -->
  <div style="padding-top: 50px" class="box fadeIn">
    <van-nav-bar
      :title="title"
      fixed
      left-arrow
      @click-left="$router.back()"
      z-index="999"
    />
    <van-row type="flex" justify="space-around">
      <van-col span="11">
        本小时能耗：{{ ECdata["hour"] || 0 }}
        <hr style="background-color: #fff; height: 1px; border: none" />
        上小时能耗：{{ ECdata["beforeHour"] || 0
        }}<span>同比{{ ECdata["percentHour"] }}%</span>
        <i
          class="el-icon-bottom-right"
          style="color: green"
          v-if="ECdata.beforeHour * 1 > ECdata.hour * 1"
        ></i>
        <i
          class="el-icon-right"
          style="color: gray"
          v-else-if="ECdata.beforeHour * 1 == ECdata.hour * 1"
        ></i>
        <i class="el-icon-top-right" style="color: red" v-else></i>
      </van-col>
      <van-col span="11" style="background-color: #24e6b2">
        本日能耗：{{ ECdata["day"] || 0 }}
        <hr style="background-color: #fff; height: 1px; border: none" />
        昨日能耗：{{ ECdata["beforeDay"] || 0 }}
        <span>同比 {{ ECdata["percentDay"] }}%</span>
        <i
          class="el-icon-bottom-right"
          style="color: green"
          v-if="ECdata.beforeDay * 1 > ECdata.day * 1"
        ></i>
        <i
          class="el-icon-right"
          style="color: gray"
          v-else-if="ECdata.beforeDay * 1 == ECdata.day * 1"
        ></i>
        <i class="el-icon-top-right" style="color: red" v-else></i>
      </van-col>
      <van-col span="11" style="background-color: #74cff5">
        本周能耗：{{ ECdata["week"] || 0 }}
        <hr style="background-color: #fff; height: 1px; border: none" />
        上周能耗：{{ ECdata["beforeWeek"] || 0
        }}<span>同比 {{ ECdata["percentWeek"] }}%</span>
        <i
          class="el-icon-bottom-right"
          style="color: green"
          v-if="ECdata.beforeWeek * 1 > ECdata.week * 1"
        ></i>
        <i
          class="el-icon-right"
          style="color: gray"
          v-else-if="ECdata.beforeWeek * 1 == ECdata.week * 1"
        ></i>
        <i class="el-icon-top-right" style="color: red" v-else></i>
      </van-col>
      <van-col span="11" style="background-color: #ffa4a1">
        本月能耗：{{ ECdata["month"] || 0 }}
        <hr style="background-color: #fff; height: 1px; border: none" />
        上月能耗：{{ ECdata["beforeMonth"] || 0 }}
        <span>同比 {{ ECdata["percentMonth"] }}%</span>
        <i
          class="el-icon-bottom-right"
          style="color: green"
          v-if="ECdata.beforeMonth * 1 > ECdata.month * 1"
        ></i>
        <i
          class="el-icon-right"
          style="color: gray"
          v-else-if="ECdata.beforeMonth * 1 == ECdata.month * 1"
        ></i>
        <i class="el-icon-top-right" style="color: red" v-else></i>
      </van-col>
    </van-row>
    <!--能耗报表E  -->
    <div class="echarts_box">
      <div class="echarts_item">
        <div class="title">
          <div>尖峰平谷</div>
          <div>
            日期<van-icon
              name="edit"
              style="margin-left: 5px"
              color="#2f9afb"
              @click="(show = true), (pikerNum = 1)"
            />
          </div>
        </div>
        <van-empty description="暂无数据" style="height: 150px" />
        <!-- <div id="timeHistory" class="history"></div> -->
      </div>
      <div class="echarts_item">
        <div class="title">
          <div>时能耗</div>
          <div>
            日期<van-icon
              name="edit"
              style="margin-left: 5px"
              color="#2f9afb"
              @click="(show = true), (pikerNum = 2)"
            />
          </div>
        </div>
        <div id="timeHistory" class="history"></div>
      </div>
      <div class="echarts_item">
        <div class="title">
          <div>日能耗</div>
          <div>
            日期<van-icon
              name="edit"
              style="margin-left: 5px"
              color="#2f9afb"
              @click="(show = true), (pikerNum = 3)"
            />
          </div>
        </div>
        <div id="dayHistory" class="history"></div>
      </div>
      <div class="echarts_item">
        <div class="title">
          <div>周能耗</div>
          <div>
            日期<van-icon
              name="edit"
              style="margin-left: 5px"
              color="#2f9afb"
              @click="(show = true), (pikerNum = 4)"
            />
          </div>
        </div>
        <van-empty description="暂无数据" style="height: 150px" />
      </div>
      <div class="echarts_item" style="margin-bottom: 50px">
        <div class="title">
          <div>月能耗</div>
          <div>
            日期<van-icon
              name="edit"
              style="margin-left: 5px"
              color="#2f9afb"
              @click="(show = true), (pikerNum = 5)"
            />
          </div>
        </div>
        <div id="monthHistory" class="history"></div>
      </div>
    </div>
    <van-popup
      v-model="show"
      round
      position="bottom"
      :style="{ height: '50%' }"
    >
      <!-- 尖峰平谷 -->
      <van-datetime-picker
        v-if="pikerNum == 1"
        v-model="PeakDate"
        type="year-month"
        title="选择年月"
        :min-date="minDate"
        :max-date="maxDate"
        @cancel="show = false"
        @confirm="changDate"
      />
      <!-- 时能耗 -->
      <van-datetime-picker
        v-if="pikerNum == 2"
        v-model="hourDate"
        type="month-day"
        title="选择月日"
        :min-date="minDate"
        :max-date="maxDate"
        @cancel="show = false"
        @confirm="changDate"
      />
      <!-- 日能耗 -->
      <van-datetime-picker
        v-if="pikerNum == 3"
        v-model="dayDate"
        type="year-month"
        title="选择年月"
        :min-date="minDate"
        :max-date="maxDate"
        @cancel="show = false"
        @confirm="changDate"
      />
      <!-- 周能耗 -->
      <!-- 月能耗 -->
      <van-picker
        v-if="pikerNum == 5"
        title="选择年"
        show-toolbar
        :columns="columns"
        @confirm="changDate"
        @cancel="show = false"
        :default-index="4"
      />
    </van-popup>
  </div>
</template>

<script>
let that;
import * as echarts from "echarts";
export default {
  data() {
    return {
      columns: [],
      pikerNum: 0,
      show: false,
      title: this.$route.query.pjName,
      ECdata: {},
      minDate: new Date(2023, 0, 1),
      maxDate: new Date(),
      hourDate: new Date(),
      PeakDate: new Date(),
      dayDate: new Date(),
      // hourDate: "",
      monthDate: new Date(),
      getBar(data, XData, ref) {
        let option = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          grid: {
            left: "5%",
            top: "10%",
            bottom: "15%",
          },
          xAxis: {
            type: "category",
            data: XData,
            axisLabel: {
              inside: false,
              color: "#fff",
            },
          },
          yAxis: {
            type: "value",
            splitLine: false,
            axisLine: {
              show: false,
            },
            axisLabel: {
              inside: false,
              color: "#fff",
            },
            splitLine: {
              lineStyle: {
                color: "#17e3ff",
                width: 0.4,
              },
            },
          },

          series: [
            {
              data: data,
              type: "bar",
              // label: {
              //   show: true,
              //   position: "top",
              //   color: "#fff",
              //   // rotate:-30,
              //   fontSize: 8,
              // },
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#17e3ff" },
                  { offset: 1, color: "#5f5dda" },
                ]),
              },
            },
          ],
        };

        var chartDom = document.getElementById(ref);
        var myChart = echarts.init(chartDom);
        myChart.setOption(option);
      },
      getMothHistory(data, XData, ref) {
        let option = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              label: {
                backgroundColor: "#6a7985",
              },
            },
          },
          toolbox: {
            orient: "vertical",
            feature: {
              saveAsImage: {},
              magicType: { show: true, type: ["line", "bar"] },
            },
          },
          grid: {
            left: "8%",
            bottom: "15%",
            top: "10%",
          },
          xAxis: [
            {
              type: "category",
              boundaryGap: false,
              data: XData,
              axisLabel: {
                inside: false,
                color: "#394b5f",
              },
            },
          ],
          yAxis: [
            {
              type: "value",
              axisLabel: {
                inside: false,
                color: "#394b5f",
              },
              splitLine: {
                lineStyle: {
                  color: "#17e3ff",
                  width: 0.4,
                },
              },
            },
          ],
          series: [
            {
              type: "line",
              stack: "Total",
              areaStyle: {},
              emphasis: {
                focus: "series",
              },
              data: data,
              itemStyle: {
                color: "rgb(156,102,248)",
              },
            },
          ],
        };

        var chartDom = document.getElementById(ref);
        var myChart = echarts.init(chartDom);
        myChart.setOption(option);
      },
    };
  },
  created() {
    that = this;
    this.getParam();
  },
  methods: {
    // 获取实时数据
    getParam() {
      that.$api.energy
        .getYoyData(this.$route.query.projectId * 1)
        .then((res) => {
          if (res.code == 200) {
            that.ECdata = res.data;
          }
        });
    },
    changDate(val) {
      this.show = false;
      switch (this.pikerNum) {
        case 1:
          this.PeakDate = val;
          break;
        case 2:
          this.hourDate = val;
          this.selectDayTags();
          break;
        case 3:
          this.dayDate = val;
          this.selectMothTags();
          break;
        case 4:
          this.weekDate;
          break;
        case 5:
          this.monthDate = val;
          this.selectYearTags();
          break;
        default:
          break;
      }
    },
    //获取尖峰平谷
    getPeak(date) {
      let from = {
        paramTags: ["D460ET", "D462EH", "D464EF", "D466EV"],
        projectId: that.getFrom.projectId,
        date: date,
        historyType: 3,
      };
      that.$api.energy.getHistoryData(from).then((res) => {});
    },
    //D460ET  J
    //D462EH  F
    //D464EF  P
    //D466EV  G
    //  时能耗统计
    selectDayTags() {
      let from = {
        paramTags: ["D400HE"],
        projectId: this.$route.query.projectId * 1,
        date: this.dayjs(that.hourDate).format("YYYY-MM-HH"),
        historyType: 2,
      };
      that.$api.energy.getHistoryData(from).then((res) => {
        if (res.code == 200) {
          let data = [];
          let xData = [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
          ];
          let obj = {};
          res.data.data[0].stream.forEach((item) => {
            obj = { ...obj, ...item };
          });
          xData.forEach((item) => {
            let num = obj[item] ? obj[item] : 0;
            if (item == 24) {
              num = obj["0"] ? obj["0"] : 0;
            }
            data.push(num);
          });
          that.getMothHistory(data, xData, "timeHistory");
        }
      });
    },
    //  获取日能耗统计
    selectMothTags() {
      let from = {
        paramTags: ["D402LHE"],
        projectId: this.$route.query.projectId * 1,
        date: this.dayjs(that.dayDate).format("YYYY-MM"),
        historyType: 3,
      };
      that.$api.energy.getHistoryData(from).then((res) => {
        if (res.code == 200) {
          let data = [];
          let obj = {};
          res.data.data[0]?.stream.forEach((item) => {
            obj = { ...obj, ...item };
          });
          let xDate = this.dayjs(that.dayDate).daysInMonth();
          let XData = [];
          for (let i = 1; i <= xDate; i++) {
            XData.push(`${i}`);
            let num = obj[i] ? obj[i] : 0;
            data.push(num);
          }
          that.getMothHistory(data, XData, "dayHistory");
        }
      });
    },
    //  获取月能耗统计
    selectYearTags() {
      let from = {
        paramTags: ["D408ME"],
        projectId: this.$route.query.projectId * 1,
        date: this.dayjs(that.monthDate).format("YYYY"),
        historyType: 4,
      };
      that.$api.energy.getHistoryData(from).then((res) => {
        if (res.code == 200) {
          let data = [];
          let obj = {};
          res.data.data[0]?.stream.forEach((item) => {
            obj = { ...obj, ...item };
          });
          let XData = [];
          for (let i = 1; i <= 12; i++) {
            XData.push(`${i}`);
            let num = obj[i] ? obj[i] : 0;
            data.push(num);
          }
          that.getMothHistory(data, XData, "monthHistory");
        }
      });
    },
    //获取周能耗统计
    selectweekTags() {
      this.weekDate = this.dayjs().format("YYYY-MM-DD");
      let from = {
        paramTags: ["D447LWE"],
        projectId: that.getFrom.projectId,
        date: this.weekDate,
        historyType: 4,
      };
      that.$api.energy.getHistoryData(from).then((res) => {});
    },
  },
  mounted() {
    const num = this.dayjs().format("YYYY");
    let arr = [];
    for (let i = 0; i < 5; i++) {
      if (i == 0) {
        arr.push(num);
      } else {
        arr.push(num * 1 - i);
        arr.push(num * 1 + i);
      }
    }
    this.columns = arr.sort();
    that.selectDayTags();
    that.selectMothTags();
    that.selectYearTags();
    // that.selectweekTags();
  },
};
</script>
<style scoped lang="less">
.box {
  height: 100%;
  overflow-y: auto;
}
.van-col {
  height: 50px;
  background-color: #ffd967;
  border-radius: 10px;
  text-align: center;
  white-space: nowrap;
  font-size: 12px !important;
  margin-bottom: 5px;
  color: #ffffff !important;
  font-weight: 700;
  line-height: 24px;
  span {
    color: #444;
    margin-left: 5px;
    font-size: 12px !important;
  }
}
.echarts_box {
  text-align: left;
  padding: 0 10px;
  .echarts_item {
    height: 200px;
    .title {
      display: flex;
      justify-content: space-between;
    }
    .history {
      width: 100%;
      height: 180px;
    }
  }
}
::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* 隐藏 IE、Edge 和 Firefox 的滚动条 */
.example {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* @import url(); 引入css类 */
</style>
